<script setup lang="ts">
// Properties and events.
//
const emit = defineEmits(['process:prev-page', 'process:next-page'])
const props = defineProps<{
  limit: number
  items: any[]
  offset: number
}>()
// Main variables.
//
const limit = toRef(props, 'limit')
const items = toRef(props, 'items')
const offset = toRef(props, 'offset')
// Computed variables.
//
const hasFewer = computed(() => offset.value >= limit.value)
const hasMore = computed(() => items.value.length >= limit.value)
// Functions.
//
const prevPage = function prevPage() {
  emit('process:prev-page')
}
const nextPage = function nextPage() {
  emit('process:next-page')
}
</script>

<template>
  <div v-show="hasFewer || hasMore" class="rk-pagination text-center py-5">
    <Btn
      base-style="dark"
      style-opts="rk-btn--prev me-3"
      :disabled="!hasFewer"
      @process:click="prevPage"
    >
      <i class="rk-icon rk-icon--text-xs fontello icon-chevron-left"></i>
      Previous
    </Btn>
    <Btn
      base-style="dark"
      style-opts="rk-btn--next"
      :disabled="!hasMore"
      @process:click="nextPage"
    >
      Next
      <i class="rk-icon rk-icon--text-xs fontello icon-chevron-right"></i>
    </Btn>
  </div>
</template>

<style lang="scss"></style>
